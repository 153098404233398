import { Form, Input } from 'antd-mobile'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import NumberInput from '@/components/number-input/number-input'
import styles from './auto-form.module.scss'
import { sexOption } from '@/pages/fill-information/options'
import { useAppDispatch } from '@/store/hooks'
import { updateForm } from '@/store/autoForm'

export interface AutoFormProps {
  handleValidate: () => string
}

let tempInput = {
  realName: '',
  age: '',
  gender: sexOption[0].value
}

const AutoForm = forwardRef<AutoFormProps>((props, ref) => {
  const dispatch = useAppDispatch()

  const [realName, setRealName] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState(sexOption[0].value)

  useEffect(() => {
    setRealName(tempInput.realName)
    setAge(tempInput.age)
    setGender(tempInput.gender)
  }, [])
  useEffect(() => {
    tempInput = {
      realName,
      age,
      gender
    }
  }, [realName, age, gender])

  const handleValidate = () => {
    if (!realName) {
      return '请输入真实姓名'
    }
    if (!/^[\u4e00-\u9fa5]+$/.test(realName)) {
      return '真实姓名不能含有非中文字符'
    }

    if (!age) {
      return '请输入年龄'
    }

    if (Number(age) < 18 || Number(age) > 99) {
      return '年龄必须在18-99之间'
    }

    dispatch(updateForm({
      realName,
      age,
      gender
    }))
    return ''
  }

  useImperativeHandle(ref, () => ({
    handleValidate
  }))

  return (
    <div className={styles.autoForm}>
      <div className={styles.form}>
        <div className={styles.formItem}>
          <div className={styles.label}>真实姓名</div>
          <div className={styles.formContent}>
            <Input
              name="realName"
              value={realName}
              onChange={setRealName}
              placeholder="请输入真实姓名"
            />
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.label}>年龄</div>
          <div className={styles.formContent}>
            <NumberInput
              numberFormat={{
                decimal: false,
                negative: false,
                leadingZero: false,
                maxLength: 2
              }}
              max={99}
              min={18}
              name="age"
              value={age}
              onChange={setAge}
              placeholder="请输入年龄"
            />
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.label}>性别</div>
          <div className={styles.radioBox}>
            {
              sexOption.map(item => (
                <div
                  className={`${styles.radio} ${gender === item.value ? styles.active : ''}`}
                  key={item.value}
                  onClick={() => setGender(item.value)}
                >{item.label}</div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
})

export default AutoForm