// 数字输入框组件
import { Input } from 'antd-mobile'
import { InputRef } from 'antd-mobile/es/components/input'
import React, { useEffect, useState } from 'react'

interface NumberFormat {
  decimal?: boolean, // 是否支持小数
  maxDecimalLength?: number, // 最大小数点位数
  maxIntegerLength?: number, // 最大整数位数
  maxLength?: number, // 最大长度
  negative?: boolean, // 是否支持负数
  leadingZero?: boolean // 是否支持前导零，如 001
  min?: string, //最小值
  max?: string //最大值
}

interface CustomChangeEvent {
  isCustomChangeEvent: true,
  target: {
    value: string
  }
}

// 判断 onChange 事件对象是否为自定义事件，自定义事件会在首次渲染组件且 value 不符合 NumberFormat 格式时触发，返回符合格式的数字
export function isCustomChangeEvent(event: React.ChangeEvent<HTMLInputElement> | CustomChangeEvent): event is CustomChangeEvent {
  return (event as CustomChangeEvent).isCustomChangeEvent === true
}

export interface NumberInputProps extends Omit<React.ComponentProps<typeof Input>, 'stringMode'> {
  value?: string;
  onChange?: (e: string) => void;
  numberFormat?: NumberFormat;
}

// 将字符串转成所需格式的数字
function parseNumber(value: string | number | undefined, numberFormatValue?: NumberFormat) {
  value = typeof value === 'undefined' ? '' : String(value).trim()
  // 默认数字格式
  const defaultNumberFormat = {
    decimal: true, // 是否支持小数
    maxDecimalLength: undefined, // 最大小数点位数
    maxIntegerLength: undefined, // 最大整数位数
    maxLength: undefined, // 最大长度
    negative: true, // 是否支持负数
    leadingZero: true, // 是否支持前导零，如 001
    min: undefined, //最小值
    max: undefined //最大值
  }
  const numberFormat = Object.assign(defaultNumberFormat, numberFormatValue)
  const firstDotIndex = value.indexOf('.')
  const firstMinusIndex = value.indexOf('-')
  // 小数
  if (numberFormat.decimal && firstDotIndex > -1) {
    let beforeText = value.slice(0, firstDotIndex).replace(/[^\d]/g, '')
    if (!beforeText.length) {
      beforeText = '0'
    }
    const afterText = value.slice(firstDotIndex + 1).replace(/[^\d]/g, '')
    value = `${beforeText}.${afterText}`
  } else {
    value = value.replace(/[^\d]/g, '')
  }

  // 整数
  if (numberFormat.maxIntegerLength && value.length > numberFormat.maxIntegerLength) {
    value = value.replace(/^(\d+)/g, intText => intText.slice(0, numberFormat.maxIntegerLength))
  }

  // 如果第一位存在减号，就加上
  if (firstMinusIndex === 0 && numberFormat.negative) {
    value = `-${value}`
  }

  // 禁止前导零
  if (/^-?0\d+/.test(value) && !numberFormat.leadingZero) {
    value = value.replace(/^(-?)0+(\d+)/, '$1$2')
  }

  // 小数点最大长度
  if (numberFormat.decimal && numberFormat.maxDecimalLength && firstDotIndex > -1) {
    value = value.replace(/\d+$/, decimalNumbers => decimalNumbers.slice(0, numberFormat.maxDecimalLength))
  }
  // 数字最大长度
  if (numberFormat.maxLength) {
    value = value.slice(0, numberFormat.maxLength)
  }

  //数字最小值
  if (numberFormat.min && +value < +numberFormat.min) {
    value = numberFormat.min
  }

  //数字最大值
  if (numberFormat.max && +value > +numberFormat.max) {
    value = numberFormat.max
  }
  return value
}

const NumberInput = React.forwardRef<InputRef, NumberInputProps>(({
  value,
  onChange,
  numberFormat,
  ...props
}, ref) => {
  const isNumber = !!numberFormat
  const [innerValue, setInnerValue] = useState(() => {
    return isNumber ? parseNumber(value, numberFormat) : value
  })
  const handleChange: NumberInputProps['onChange'] = val => {
    const newValue = isNumber ? parseNumber(val, numberFormat) : val
    setInnerValue(newValue)
    // console.log('newValue', newValue)

    onChange && onChange(newValue)
    setTimeout(() => {
      onChange && onChange(newValue)
    })
  }
  useEffect(() => {
    // if (value !== innerValue) {
    const newVal = isNumber ? parseNumber(value, numberFormat) : value
    setInnerValue(newVal)
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    const newValue = parseNumber(value, numberFormat)
    if (newValue !== value) {
      onChange && onChange(newValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Input
      ref={ref}
      placeholder="请输入数字"
      value={innerValue}
      onChange={handleChange}
      {...props}
    ></Input>
  )
})

export default NumberInput
