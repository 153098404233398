/* eslint-disable camelcase */
// 拦截弹窗
import React, { useEffect, useMemo, useState } from 'react'
import styles from './intercept-modal.module.scss'
import Icon from '@/components/icon/icon'
import NumberInput from '@/components/number-input/number-input'
import classNames from 'classnames'
import { ENTER_AGREEMENT } from '@/constants/storage'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Storage, debounce, isPhone } from '@bihu/common-js'
import { Toast } from 'antd-mobile'
import activityApi from '@/apis/activity'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'

interface Props {

    /** 用户已填手机号 */
    phoneNum: string

    /** 用户是否勾选协议 */
    check:boolean

    /** 是否显示拦截弹窗 */
    showPrompt: boolean

    /** 确认继续导航 */
    confirmNavigation?: () => void

    /** 取消本次导航 */
    cancelNavigation?: () => void

    /** 注册函数 */
    register: (code: string, phone: string, skinNum:number) => void
}

const InterceptModal: React.FC<Props> = ({ showPrompt, confirmNavigation, cancelNavigation, register, phoneNum, check }) => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const [phone, setPhone] = useState('')
  // 选择协议
  const [checked, setChecked] = useState(false)
  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)
  // 是否显示验证码弹窗
  const [showPopup, setShowPopup] = useState(false)
  // 验证码
  const [code, setCode] = useState('')
  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }
  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(`/${path}-agreement?isShowHeader=true`)
  }
  const cancleBtn = () => {
    cancelNavigation && cancelNavigation()
  }

  const openPopup = () => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!checked) {
      return Toast.show('请勾选注册和隐私协议')
    }
    if (!time) {
      getCode()
    }
    cancelNavigation && cancelNavigation()
    setShowPopup(true)
  }

  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone,
        channelCode: adChannelCode
      })
      localStorage.setItem('user_phone', phone)

      // 前端神策埋点 - 用户获取验证码
      sensors.track(SensorsType.cilck_get_verification_code, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }
    // Toast.show('获取验证码成功')
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  useEffect(() => {
    setPhone(phoneNum)
    setChecked(check)
  }, [phoneNum, check])

  const debounceRegister = useMemo(() => debounce(register, 500, false), [channelValue])
  return (
    <>
      {/* 挽留弹窗 */}
      <div className={styles.interceptModalBox} style={{ display: showPrompt ? 'block' : 'none' }}>
        <div className={styles.modalBox}>
          <img className={styles.interceptMoney} src={require('@imgs/register/Intercept-money.png')} alt="intercept-img" />
          <div className={styles.interceptBox}>
            <div className={styles.creditLimit}>测测自己有多少额度</div>
            <div className={styles.creditIntro}>1分钟极速注册，3分钟人工审核</div>
            <div className={styles.phoneBox}>
              <Icon type="phoneNum" size={20} className={styles.phoneNum}></Icon>
              <div className={styles.line}></div>
              <NumberInput
                placeholder="凭手机号领取额度"
                value={phone}
                numberFormat={{
                  decimal: false,
                  negative: false,
                  leadingZero: false,
                  maxLength: 11
                }}
                onChange={val => {
                  setPhone(val)
                }}
              />
            </div>
            <div className={styles.getCms} onClick={openPopup}>免费查看额度</div>
            <div className={classNames(styles.agreement)}>
              <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
              <div className={styles.agreementText}>
                <span>阅读并同意</span>
                <span
                  className={styles.primary}
                  onClick={() => {
                    openView('register')
                  }}
                >
              《用户注册服务协议》
                </span>
            和
                <span className={styles.primary} onClick={() => {
                  openView('privacy')
                }}>《隐私政策》</span>
                <span className={styles.primary} onClick={() => {
                  openView('information')
                }}>《个人信息共享授权协议》</span>
              </div>
            </div>
          </div>
          <div className={styles.cancleBtn} onClick={cancleBtn}>
            <Icon type="xiangbei-close" className={styles.closeIcon} size={20}></Icon>
          </div>
        </div>
      </div>
      {/* 验证码弹窗 */}
      <div className={classNames(styles.dialogOverlay, showPopup && styles.showPopup)}>
        <div className={styles.dialogMain}>
          <div className={styles.close} onClick={() => setShowPopup(false)}>X</div>
          <div className={styles.inputBox}>
            <NumberInput
              placeholder="请输入验证码"
              value={code}
              numberFormat={{
                decimal: false,
                negative: false,
                maxLength: 6
              }}
              onChange={val => {
                setCode(val)
              }}
            />
            {
              time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                : <span className={styles.code} onClick={getCode}>获取验证码</span>
            }
          </div>
          <div className={styles.getQuota} onClick={() => debounceRegister(code, phone, 15)}>获取额度</div>
        </div>
      </div>
    </>
  )
}

export default InterceptModal
