// 皮肤-金信天渠道
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './skin19.module.scss'
import { Button, Card, Divider, Input, PickerView, Popup, Toast } from 'antd-mobile'
import NumberInput from '@/components/number-input/number-input'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CompanyType } from '@/constants/common'
import { PhoneInput } from '../phone-input/phone-input'
import classNames from 'classnames'
import { JINXINTIAN_RSA_PHONE } from '@/constants/entryQuery'
import { debounce, isPhone, Storage } from '@bihu/common-js'
import { ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import activityApi, { SmsSendMsg } from '@/apis/activity'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import { PickerColumn, PickerValue } from 'antd-mobile/es/components/picker-view'
import moment from 'moment'

interface Props {
  channelDetail: any // 渠道详情
  register: (code: string, phone: string, skinNum:number, searchParamsFromCom?: URLSearchParams) => void
  loginAfterLocation: () => void
  onPhoneOrCheckedChange:(phone:string, check:boolean) => void
}

const Skin19: React.FC<Props> = props => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const nextMonth = moment()
    .add(1, 'months')
    .format('MM-DD')
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const { channelDetail, register, loginAfterLocation, onPhoneOrCheckedChange } = props

  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')
  // 借款金额
  const [amount, setAmount] = useState<string>('50000')

  // 是否显示验证码弹窗
  const [showPopup, setShowPopup] = useState(false)

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  //还款期数弹窗显示
  const [periodShow, setPeriodShow] = useState(false)
  //借款用途弹窗显示
  const [useWayShow, setUseWayShow] = useState(false)
  //暂存周期
  const [tempPeriod, setTempPeriod] = useState<PickerValue[]>(['12个月'])
  //暂存借款用途
  const [tempUseWay, setTempUseWay] = useState<PickerValue[]>(['个人日常消费'])
  //选择周期
  const [period, setPeriod] = useState<PickerValue[]>(['12个月'])
  //选择借款用途
  const [useWay, setUseWay] = useState<PickerValue[]>(['个人日常消费'])
  //显示填写其他手机号
  const [otherPhoneShow, setOtherPhoneShow] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  const periodColumns = [
    [
      {
        label: '3个月',
        value: '3个月'
      },
      {
        label: '6个月',
        value: '6个月'
      },
      {
        label: '12个月',
        value: '12个月'
      },
      {
        label: '24个月',
        value: '24个月'
      },
      {
        label: '36个月',
        value: '36个月'
      },
    ]
  ]

  const useWayColumns = [
    {
      label: '个人日常消费',
      value: '个人日常消费'
    },
    {
      label: '装修',
      value: '装修'
    },
    {
      label: '医疗',
      value: '医疗'
    },
    {
      label: '教育',
      value: '教育'
    },
    {
      label: '其他',
      value: '其他'
    },
  ]

  const formatH5ConfigBottomText = () => {
    if (channelDetail && channelDetail.H5ConfigItem && channelDetail.H5ConfigItem.bottomTextStatus === 1 && channelDetail.H5ConfigItem.bottomText) {
      const arr = channelDetail.H5ConfigItem.bottomText.split('\n')
      return (
        <div className={styles.tipBox}>
          {
            arr.map((item: string, index: number) => (
              <div key={index} className={styles.tip}>{ item }</div>
            ))
          }
        </div>
      )
    }
  }

  // 获取验证码
  const getCode = async() => {
    const smsParams: SmsSendMsg = {
      mobile: +phone,
      channelCode: adChannelCode
    }
    if (!smsParams.mobile && search.get(JINXINTIAN_RSA_PHONE)) {
      smsParams.encryptionMobile = decodeURIComponent(search.get(JINXINTIAN_RSA_PHONE) as string) as string
      smsParams.mobile = ''
    }

    console.log('smsParams', smsParams)

    if (!smsParams.encryptionMobile) {
      if (!phone) {
        return Toast.show('请输入手机号码')
      }
      if (!isPhone(phone)) {
        return Toast.show('手机号码格式有误')
      }
    }

    countDownFn()

    try {
      await activityApi.smsSend(smsParams)
      localStorage.setItem('user_phone', phone)

      // 前端神策埋点 - 用户获取验证码
      sensors.track(SensorsType.cilck_get_verification_code, {
        // eslint-disable-next-line camelcase
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }
    // Toast.show('获取验证码成功')
  }

  const openPopup = () => {

    if (!search.get(JINXINTIAN_RSA_PHONE)) {
      if (!phone) {
        return Toast.show('请输入手机号码')
      }
      if (!isPhone(phone)) {
        return Toast.show('手机号码格式有误')
      }
    }
    if (!checked) {
      setIsShake(true)
      setTimeout(() => {
        setIsShake(false)
      }, 100)
      return Toast.show('请勾选注册和隐私协议')
    }
    if (!time) {
      getCode()
    }
    setShowPopup(true)
  }

  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  const debounceRegister = useMemo(() => debounce(register, 500, false), [])

  const popDownDrawer = (type: string) => {
    switch (type) {
      case 'period':
        setPeriodShow(true)
        break
      case 'useWay':
        setUseWayShow(true)
        break
      default: break
    }
  }

  const calcSum = () => {
    const month = period[0] ? period[0].replace(/[^\d]/g, '') : ''
    const monthPay = +amount / +month
    const monthValuePercent = 0.108 / +month
    return (monthPay + (+amount * monthValuePercent)).toFixed(2)
  }

  const handleChangeOtherPhone = () => {
    setPhone('')
    phoneInputRef.current?.handleEdit()
    setTime(0)
    setOtherPhoneShow(true)
  }


  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  useEffect(() => {
    onPhoneOrCheckedChange(phone, checked)
  }, [phone, checked])

  useEffect(() => {
    const hasToken = Storage.get(TOKEN)
    // 是否需要获取定位
    if (adChannelCode && hasToken && channelDetail?.location) {
      loginAfterLocation()
    }
  }, [])

  const handleChangeValue = (value: string) => {
    const val = +value
    if (val < 1000) {
      setAmount('1000')
    } else if (val > 200000) {
      setAmount('200000')
    } else {
      setAmount(val.toString())
    }
  }

  const debounceSave = useMemo(() => debounce(handleChangeValue, 0, false), [])

  const phoneInputRef = useRef<any>(null)

  useEffect(() => {
    console.log('amount', amount)
  }, [amount])
  return (
    <div className={styles.skin19}>
      <div className={styles.borrowBox}>
        <div style={{
          fontWeight: '600',
          fontSize: '16px',
          height: '32px',
          padding: '0 10px'
        }}>借</div>
        <span>
          <NumberInput
            value={amount}
            defaultValue="50000"
            placeholder=""
            min={1000}
            max={200000}
            onBlur={e => debounceSave(e.target.value)}
            numberFormat={{
              decimal: false,
              negative: false,
              maxLength: 6,
            }}
            onChange={val => {
              setAmount(val)
            }}
          ></NumberInput></span>
        <div style={{
          paddingBottom: '6px',
          fontSize: '13px',
          color: '#1890FF',
          width: '262px'
        }} onClick={e => setAmount('200000')}>全部借出200000</div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.borrowBottomText}>年化利率<span className={styles.number}> 10.8%-36% </span>(单利)，实际以审核为准</div>
      <div className={styles.borrowCondition}>
        <Card style={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
        bodyStyle={{
          borderBottom: '1px solid #F0F0F0',
        }}>
          <span className={styles.cardLeft}>还款方式</span>
          <div className={styles.cardRight}>
            <div>随借随还</div>
            <div style={{ color: '#BFBFBF' }}>按日计算，提前还0手续费</div>
          </div>
        </Card>
        <Card bodyStyle={{
          borderBottom: '1px solid #F0F0F0',
        }}>
          <span className={styles.cardLeft}>还款期数</span>
          <div className={styles.cardRight} onClick={e => popDownDrawer('period')}>
            <div>{period}</div>
            <div style={{ color: '#BFBFBF' }}>年化利率 10.8-36%  (单利)，以实际为准</div>
          </div>
        </Card>
        <Card style={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px'
        }}>
          <span className={styles.cardLeft}>还款计划</span>
          <div className={styles.cardRight}>首期 {nextMonth.replace('-', '月').concat('日')}，应还{
            (+amount >= 1000 && +amount <= 200000) ? calcSum() : ' -'} 元</div>
        </Card>
      </div>
      <div style={{
        fontWeight: 400,
        fontSize: '10px',
        color: '#8C8C8C',
        padding: '10px 0'
      }}>* 贷款试算功能，进作为借还款信息参考</div>
      <div className={styles.borrowCondition}>
        <Card style={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }} bodyStyle={{
          borderBottom: '1px solid #F0F0F0',
        }}>
          <span className={styles.cardLeft}>利率</span>
          <div className={styles.cardRight}>年化利率 10.8-36%  (单利)</div>
        </Card>
        <Card style={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px'
        }}>
          <span className={styles.cardLeft}>借款用途</span>
          <div className={styles.cardRight} onClick={e => popDownDrawer('useWay')}>{`${useWay} >`}</div>
        </Card>
      </div>
      <div style={{ marginTop: '10px' }} className={styles.borrowCondition}>
        <Card className={styles.phoneBox} style={{
          borderRadius: '8px',
          display: 'flex'
        }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%'
        }}>
          {<PhoneInput
            prefix={<span style={{ width: '25%' }}>手机号</span>}
            ref={phoneInputRef}
            customizePlaceholder="凭手机号注册申请 (已加密)"
            customizeEncryptPhoneStyle={{
              fontWeight: 600,
              fontSize: '24px',
              fontFamily: 'PingFang SC'
            }}
            showEditButton={false}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '12px',
              marginBottom: '12px'

            }}
            value={phone}
            onChange={val => {
              setPhone(val)
            }}
          />}
          <div className={styles.getCms} onClick={openPopup}>立即申请</div>
          {search.get(JINXINTIAN_RSA_PHONE) && <div><Button color="primary" fill="none" className={styles.otherLogin} onClick={handleChangeOtherPhone}>其他账号登录</Button></div>}
          <div className={classNames(styles.agreement, isShake && styles.animation)}>
            <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
            <div style={{ width: '92%' }}>
              <span>阅读并同意</span>
              <span
                className={styles.primary}
                onClick={() => {
                  openView('register')
                }}
              >
              《用户注册服务协议》
              </span>
              <span className={styles.primary} onClick={() => {
                openView('privacy')
              }}>《隐私政策》</span>
              和
              <span className={styles.primary} onClick={() => {
                openView('information')
              }}>《个人信息共享授权协议》</span>
            </div>
          </div>
        </Card>
      </div>
      {
        channelDetail && channelDetail.H5ConfigItem && channelDetail.H5ConfigItem.bottomTextStatus === 1 && channelDetail.H5ConfigItem.bottomText
          ? formatH5ConfigBottomText()
          : <div className={styles.tipBox}>
            <div className={styles.tip}>本平台是信息服务平台，不提供放贷业务</div>
            <div className={styles.tip}>平台向您展示的贷款产品或贷款咨询服务是由贷款服务机构</div>
            <div className={styles.tip}>(泛指为您提供贷款咨询服务或贷款发放服务的机构)</div>
            <div className={styles.tip}>或贷款中介咨询机构提供</div>
            <div className={styles.tip}>平台展示的贷款产品年利率范围10.8-36% (单利) </div>
            <div className={styles.tip}>具体利率以第三方实际放款结果为准</div>
            <div className={styles.tip}>您最终获取的贷款额度、利率、放款时间</div>
            <div className={styles.tip}>以第三方机构实际审批结果为准</div>
            <div className={styles.tip}>温馨提示:请根据个人能力合理贷款，理性消费，避免逾期</div>
            <div className={styles.tip}>请不要相信任何要求您支付费用的信息、邮件、电话等不实信息</div>
            {channelValue?.includes('xinyonghuaRegisterPage') && <div className={styles.tip}>贷款资金由正规金融持牌放款机构提供</div>}
            {channelValue?.includes('registerTips') && <div className={styles.tip}>资金来源：{ CompanyType.ZAXY_COMPANY.company}</div>}
            {channelValue?.includes('registerTips') && <div className={styles.tip}>贷款有风险 借款需谨慎</div>}
            {
              channelValue.includes('zhonganXy') && <div className={styles.tip}>{ `${CompanyType.ZAXY_COMPANY.recordNumber} | ${CompanyType.ZAXY_COMPANY.company}`}</div>
            }
            <div className={styles.tip}>客服电话：4006686025</div>
          </div>
      }
      <div className={classNames(styles.dialogOverlay, showPopup && styles.showPopup)}>
        <div className={styles.dialogMain}>
          <div className={styles.close} onClick={() => setShowPopup(false)}>X</div>
          <div className={styles.inputBox}>
            <NumberInput
              placeholder="请输入验证码"
              value={code}
              numberFormat={{
                decimal: false,
                negative: false,
                maxLength: 6
              }}
              onChange={val => {
                setCode(val)
              }}
            />
            {
              time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                : <span className={styles.code} onClick={getCode}>获取验证码</span>
            }
          </div>
          <div className={styles.getQuota} onClick={() => debounceRegister(code, phone, 15, search)}>获取额度</div>
        </div>
      </div>
      <Popup
        className={styles.popup}
        visible={periodShow}
        onMaskClick={() => {
          setPeriodShow(false)
        }}
        onClose={() => {
          setPeriodShow(false)
        }}
        bodyStyle={{
          height: '40vh',
          borderRadius: '8px'
        }}
      >
        <div className={styles.popupButton} style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px'
        }}>
          <Button color="default" fill="none" onClick={e => {
            setPeriod(period)
            setPeriodShow(false)
          }}>取消</Button>
          <Button color="danger" fill="none" onClick={e => {
            setPeriod(tempPeriod)
            setPeriodShow(false)
          }}>确定</Button>
        </div>
        <PickerView value={period} columns={periodColumns} onChange={val => setTempPeriod(val)}/>
      </Popup>
      <Popup
        className={styles.popup}
        visible={useWayShow}
        onMaskClick={() => {
          setUseWayShow(false)
        }}
        onClose={() => {
          setUseWayShow(false)
        }}
        bodyStyle={{
          height: '40vh',
          borderRadius: '8px'
        }}
      >
        <div className={styles.popupButton} style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px'
        }}>
          <Button color="default" fill="none" onClick={e => {
            setUseWay(useWay)
            setUseWayShow(false)
          }}>取消</Button>
          <Button color="danger" fill="none" onClick={e => {
            setUseWay(tempUseWay)
            setUseWayShow(false)
          }
          }>确定</Button>
        </div>

        <PickerView value={useWay} columns={[useWayColumns]} onChange={val => setTempUseWay(val)}/>
      </Popup>
    </div>
  )
}

export default Skin19
