import { aesDecrypt, encryptString, rsaEncrypt } from '@/utils/utils'
import styles from './phone-input.module.scss'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { COMMON_AES_SECRET } from '@/constants/AESSecret'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import NumberInput from '@/components/number-input/number-input'
import Icon from '@/components/icon/icon'
import useDevAPI from '@/hooks/devApi'
import { COMMON_RSA_SECRET_BASE64 } from '@/constants/RSASecret'
import apiIndex from '@/apis/index'
import { JINXINTIAN_RSA_PHONE } from '@/constants/entryQuery'


let phoneTemp = '' // 用于回显
let phoneInputUsefulTemp = true // 用于回显

interface Props{
  prefix?: React.ReactNode,
  value?: string
  customizePlaceholder?: string
  customizeEncryptPhoneStyle?: React.CSSProperties
  showEditButton?: boolean
  onChange?: (value: string) => void
  style?: React.CSSProperties
}
type PhoneInputProps = {
  handleEdit: () => void
}
export const PhoneInput = React.forwardRef<PhoneInputProps, Props>((props, ref) => {
  const { prefix, value, onChange, style, customizePlaceholder, customizeEncryptPhoneStyle, showEditButton = true } = props
  const [search, setSearch] = useSearchParams()

  const { generateRandomPhoneNumber, getEnvironment } = useDevAPI()

  const { channelValue } = useSelector((state: RootState) => state.channel)
  const placeholder = useMemo(() => {
    if (channelValue.includes('huanbeiRegiste')) {
      return '输入手机号'
    }
    return '输入手机号领取金额'
  }, [channelValue])

  const [phone, setPhone] = useState('')
  const handleSetPhone = useCallback((val: string) => {
    setPhone(val)
    phoneTemp = val
    onChange?.(val)
  }, [onChange])

  // 输入后回显
  useEffect(() => {
    // debugger
    handleSetPhone(phoneTemp)
    handleSetPhoneInputUseful(phoneInputUsefulTemp)
  }, [])

  // H5 链接带phone参数时处理，解密手机号码
  let aesDecryptPhone = search.get('phone') || ''

  // 金信天 RSA 解密
  let jinXinTianRsaDecryptPhone = search.get(JINXINTIAN_RSA_PHONE) || ''

  // 编辑时清空
  // 金信天 RSA 解密要求清空
  const editClear = useMemo(() => {
    return !!jinXinTianRsaDecryptPhone
  }, [jinXinTianRsaDecryptPhone])

  const [phoneInputUseful, setPhoneInputUseful] = useState(true)
  const handleSetPhoneInputUseful = useCallback((val: boolean) => {
    setPhoneInputUseful(val)
    phoneInputUsefulTemp = val
  }, [editClear, setPhone])

  const handleEdit = () => {
    // 金信天点击编辑时要求清空手机号
    if (editClear) {
      setPhone('')
      search.delete(JINXINTIAN_RSA_PHONE)
      setSearch(search)
      phoneTemp = ''
    }
    handleSetPhoneInputUseful(true)
  }

  // 中飞 H5 解密手机号
  const handleAesDecryptPhone = useCallback(() => {
    // 中飞 H5 解密密钥
    const { key, iv } = COMMON_AES_SECRET
    let decryptPhoneNum = aesDecrypt(decodeURIComponent(aesDecryptPhone) as string, key, iv)
    if (decryptPhoneNum) {
      handleSetPhone(decryptPhoneNum)
      handleSetPhoneInputUseful(false)
    }
  }, [aesDecryptPhone, handleSetPhone, handleSetPhoneInputUseful])

  // 金信天获取手机号
  const handleJinXinTianRsaDecryptPhone = useCallback(async() => {
    const phoneNumber = await apiIndex.getPhoneNumByRSADecrypt(encodeURIComponent(jinXinTianRsaDecryptPhone) as string)
    if (phoneNumber) {
      handleSetPhone(phoneNumber)
      handleSetPhoneInputUseful(false)
    }
  }, [jinXinTianRsaDecryptPhone, handleSetPhone, handleSetPhoneInputUseful])

  useEffect(() => {
    const h5PhoneDecrypt = () => {
      try {
        // 使用 aes 解密 手机号
        if (aesDecryptPhone) {
          handleAesDecryptPhone()
          return
        }

        // 金信天 RSA 解密
        if (jinXinTianRsaDecryptPhone) {
          handleJinXinTianRsaDecryptPhone()
          return
        }

        // 测试环境随机生成手机号
        if (getEnvironment() !== 'prod') {
          const radomPhoneNum = generateRandomPhoneNumber()
          localStorage.setItem('user_phone', radomPhoneNum)
          handleSetPhone(radomPhoneNum)
        }
      } catch (err) {
        console.log('err:', err)
      }
    }

    // 回显判断
    if (!phoneTemp) {
      h5PhoneDecrypt()
    }
  }, [aesDecryptPhone, jinXinTianRsaDecryptPhone])

  useImperativeHandle(ref, () => (
    {
      handleEdit,
      jinXinTianRsaDecryptPhone
    }
  ))

  return (
    phoneInputUseful ? (
      <div className={styles.phoneBox} style={style}>
        {prefix}
        <NumberInput
          placeholder={ customizePlaceholder ? customizePlaceholder : placeholder }
          value={phone}
          numberFormat={{
            decimal: false,
            negative: false,
            leadingZero: false,
            maxLength: 11
          }}
          onChange={val => {
            handleSetPhone(val)
          }}
        />
      </div>
    ) : (
      <div className={styles.phoneNotNeedInput}>
        <span style={customizeEncryptPhoneStyle ? customizeEncryptPhoneStyle : undefined} className={styles.encryptPhone}>{encryptString(phone, 3, 4)}</span>
        { showEditButton ? <Icon type="Edit" size={20} className={styles.editIcon} onClick={handleEdit}></Icon> : null }
      </div>
    )
  )
})
