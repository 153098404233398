/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import Header from '@/components/header/header'
import styles from './jkr-register-agreement.module.scss'
import classNames from 'classnames'
import { getParams, formatTime } from '@bihu/common-js'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useSearchParams } from 'react-router-dom'
import { CompanyType } from '@/constants/common'

interface Props{}
// 聚客融注册协议
const JukerongRegisterAgreement: React.FC<Props> = () => {
  const [search] = useSearchParams()
  const [isShowHeader, setIsShowHeader] = useState(false)
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [phone, setPhone] = useState('')
  const [date, setDate] = useState<number>(Date.now())
  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
  }, [])

  const getAgreementCompany = () => {
    switch (true) {
      case channelValue?.includes('jfxd'):
        return CompanyType.JFXD_COMPANY.company
      default:
        return CompanyType.JKR_COMPANY.company
    }
  }

  useEffect(() => {
    // console.log()
    let phoneNum = search.get('phone')
    let dateNum = search.get('date')
    if (phoneNum) {
      setPhone(phoneNum)
    }
    if (dateNum) {
      setDate(+dateNum)
    }
  }, [])

  const signature = useMemo(() => {
    switch (true) {
      case channelValue?.includes('jurongyi'):
        return '聚融易'
      default:
        return '聚好借'
    }
  }, [channelValue])

  // 聚客融隐私协议
  const jkrRegister = () => {
    return (
      <>
        <p className={styles.bold}>
          {/* <br /> */}
          特别提示：
          <br />
          在您选择使用【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5提供的服务前，请确认您已仔细阅读本协议的全部内容(特别是以粗体字样或下划线标注的内容，包括但不限于免除或者限制责任条款)。一旦您自主明示授权勾选“同意”本协议或开始以其他方式使用【{signature}】所提供的服务，均表示您已经阅读、知悉、理解并完全同意本协议的全部内容，接受本协议的约束，如您不同意本协议的任何内容，或者无法准确理解本协议任何条款的含义，请不要进行后续操作或停止注册。
          <br />
           本版本更新日期：2024年05月10日
          <br />
           本版本生效日期：2024年05月10日
          <br />
           【签订主体】
          <br />
          【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5(下称“平台”/“我们”)系由{getAgreementCompany() }运营。
          <br />
           【协议的生效与修改】
          <br />
           您同意我们根据法律法规的要求或业务运营的需要，对本协议条款不定期进行增加、删减、修改或补充，您应不时地审阅本协议条款以及经参引而并入其中的其他规则。除非另有规定，否则所修改的内容将在发布之时视为生效，您对本平台的注册、使用、或继续使用等行为均表明您接受我们的修改。
           如果您不同意我们对本协议任一条款的修订，则请勿注册或停止使用本平台提供的服务，或您可以主动联系我们取消本平台提供的服务。
          <br />
           【协议的组成】
          <br />
           所有【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5已经发布的或将来可能发布的规则均为本协议不可分割的组成部分。除另行明确声明外，任何【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5提供的服务均受本协议及其相关协议的约束。
          <br />
           一、定义
          <br />
           用户：年满18周岁，具有完全民事行为能力且具有一定偿还能力的，有了解贷款产品及/或借贷需求的自然人。
           【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5：指为用户提供贷款产品及贷款服务产品页面展示、信息传递、咨询、广告推荐等服务的一站式移动互联网贷款产品智选平台。
           平台合作第三方：指【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5内所展示的与本平台签署了合作协议，并在本平台展示其有权运营的产品或服务的贷款服务方。包括但不限于在您明示授权后，同您建立贷款服务关系的第三方金融服务方、持牌互联网贷款产品机构、类金融机构、贷款信息咨询服务机构等。
          <br />
           二、服务的提供
          <br />
           2.1服务内容
          <br />
           2.1.1 页面展示
          <br />
           本平台将为您展示包括但不限于持牌互联网贷款产品信息、贷款咨询机构信息、类金融机构、贷款信息咨询服务机构的产品信息等。
          <br />
           2.1.2 信息传递
          <br />
           本平台基于您的自主明示授权及同第三方的合作，可能将您在本平台实际操作的包括但不限于申请、借款、提现、还款等信息指令根据实际服务情形，传递给您授权的第三方贷款机构或服务机构。
          <br />
           2.1.3 广告
          <br />
           本平台为您提供持牌互联网贷款产品机构、类金融机构、贷款信息咨询服务机构的广告信息，具体以页面实际展示为准。
          <br />
           2.1.4您知悉、理解并确认，如您通过我们的服务，与平台合作的第三方建立借贷服务关系，该服务实际由第三方提供，本平台不会以任何形式介入您和第三方的服务关系，您应审慎阅读第三方机构的服务协议等文件，您知悉您为自主选择第三方的服务，请谨慎选择以保证您的信息安全及合法权益。
          <br />
           2.1.5本平台只为您提供贷款机构及贷款服务机构信息展示、推荐及贷款信息咨询等服务，平台承诺不会向您收取任何费用，且本平台非放款方，不参与任何放款，我们不会对您进行任何催收类行为。(如有关机构向您收取担保费、服务费等各类费用与【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5向您提供的服务不存在任何关联关系，如您与第三方之间就双方交易产生纠纷，您应与第三方自行友好协商解决，与本平台无关。
          <br />
           2.2接受服务须知
          <br />
           2.2.1因您个人过错(如：个人信息填写错误，错误点击等行为)导致无法正常使用本平台的服务，或者实际问题解决方应为平台合作的第三方，本平台对此不承担任何责任，但平台会在知悉您的需求后积极协助您同平台合作的第三方建立沟通。
          <br />
           2.2.2您确认，您在使用本平台所展示的服务流程中所自主明示确认的交易状态、信息提供等行为将成为本平台为您进行相关交易或操作(包括但不限于订立合同等)的明确指令。您同意本平台有权按相关指令依据本协议和/或有关文件和规则对相关事项进行处理，由此产生的法律后果由您自行承担。
          <br />
           2.3其他
          <br />
           以上并不能揭示您通过本平台服务选择贷款产品的全部规则及可能承担的所有风险。您在接受本平台服务前，请尽可能了解相关规则、查阅相关合同，正视风险，谨慎决策。
          <br />
           三、电子合同
          <br />
           3.1您在使用本平台所提供的服务过程中订立的协议采用电子合同的形式，一经您点击确认即视为生效，对双方均具有约束力且可以有一份或者多份，并且每一份具有同等法律效力。您可以在{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5内“我的--设置--用户协议”查看您已实际签署的电子文件。
          <br />
           3.2 您知悉并了解您向本平台提供的相关信息需真实、完整、准确，否则将影响您正常使用本平台的服务，如在您使用本平台服务期内上述信息发生任何变更或上述信息失效，您应当及时在本平台进行更新或告知本平台为您进行更新，否则因此造成的损失由您自行承担。
          <br />
           四、信息提供和隐私保护
          <br />
           4.1信息提供
          <br />
           4.1.1您在注册账号或使用本平台服务的过程中，需要填写一些必要的信息，根据国家法律法规的规定及您的服务需求，您需要填写真实的身份信息。在某些特定服务中，若您填写的信息不完整，则可能导致您无法使用本平台的服务或在使用过程中受到限制。
          <br />
           4.1.2平台不负责审核您提供的个人信息的真实性、准确性及完整性，因信息不真实、不准确或不完整而引起的任何问题及后果，由您自行承担，且您应保证我们免受由此而产生的任何损害或责任。若我们发现您提供的个人信息是虚假、不准确或不完整的，我们有权自行决定终止向您提供服务且无需事先经过您的同意。
          <br />
           4.2隐私保护
          <br />
           4.2.1保护您的个人信息安全是我们的重点原则，我们通过《{signature}隐私政策》向您详细说明我们在为您提供服务的过程中如何收集、使用、存储、共享、传递这些信息、并告知您收集的原因以及我们为您提供的访问、更新、删除和保护这些信息的方式;
          <br />
           4.2.2我们保证采用行业惯例以保护您的资料，我们通过各种技术手段和强化内部管理等办法提供隐私保护服务功能，充分保护您的个人信息安全。但您知悉并理解，鉴于技术限制，我们无法确保您的个人信息完全不被泄露。
          <br />
           4.3授权使用
          <br />
           4.3.1您已明确授权本平台及本平台合作的第三方，基于提供服务、履行协议、解决争议、保障交易安全等目的，在遵守本协议及《{signature}隐私政策》的前提下，使用您所授权的信息。具体的授权使用信息范围以及第三方使用您授权信息的范围及目的，您可以通过本平台的《{signature}隐私政策》进行了解。
           您知悉、理解并同意我们有权在您的明示授权下对您提供的、我们自行收集的及通过第三方收集的您的个人信息、您申请服务时的相关信息、您在使用服务时储存在平台的非公开内容以及您的其他个人信息等享有收集、使用、存储、共享、传递的权利，具体方式包括但不限于：
          <br />
           (1)出于为您提供服务的需要在本平台显示您的个人资料;
          <br />
           (2)由人工或自动程序等对您的个人资料进行获取、评估、整理、存储;
          <br />
           (3)使用您提供的联系方式与您联络并向您传递有关服务和管理方面的信息，包括不限于通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务推广信息等服务信息，您如果不同意接收相关信息，您可以通过相应的退订功能或相关提示进行退订;
          <br />
           (4)对您的个人资料进行分析整合并向为您提供服务的第三方提供完成该项服务必要的信息。
          <br />
           (5)其他必要的使用及披露您个人资料的情形。
          <br />
           五、注册
          <br />
           5.1注册用户主体资格
          <br />
           您应保证您为年满18周岁，且具有完全民事行为能力及一定偿还能力的自然人。您应保证您不是在校学生。如您未满足前述条件，我们一经查实，将自动终止为您提供任何服务，由此导致的全部法律风险及法律责任由您自行承担。
          <br />
           5.2登陆账户的保护
          <br />
           我们将向您提供一个登陆账户，注册成功之后仅供您个人使用，您应对您的平台登陆账户的安全负责。我们承诺非经法定原因、本协议的约定、未经您的明示许可，我们不会向任何第三方透露您的任何信息。
           如果您发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即以有效方式告知我们，您理解我们对您的请求采取行动需要合理时间，且我们应您的请求采取的行动可能无法避免或阻止侵害后果的形成或扩大，除平台存在法定过错外，我们不承担任何法律责任。
          <br />
           5.3账户禁止
          <br />
           除非有法律规定或行政司法机关的指令，且征得我们的同意，否则您的账户不得以任何方式转让、借用、赠与、继承(与账户相关的财产权益除外)或在第三方平台上进行展示或售卖。否则，由此给您造成的一切损失，由您自行承担。
          <br />
           六、【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5使用规范
          <br />
           6.1您在使用平台的部分服务时，平台需要对您身份进行特别验证，具体的验证方式以实际服务页面显示为准。
          <br />
           6.2您在使用我们提供的服务过程中，必须遵守国家的相关法律法规，不得通过平台发布、复制、上传、散播、分发、存储、创建或以其它方式公开含有以下内容的信息：
          <br />
           (1)反对宪法所确定的基本原则的;
          <br />
           (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一，损害国家荣誉和利益的;
          <br />
           (3)煽动民族仇恨、民族歧视，破坏民族团结的;
          <br />
           (4)破坏国家宗教政策，宣扬邪教和封建迷信的;
          <br />
           (5)散布谣言，扰乱社会秩序，破坏社会稳定的;
          <br />
           (6)侮辱或者诽谤他人，侵害他人合法权益的;
          <br />
           (7)其他违反宪法和法律、行政法规或规章制度的;
          <br />
           (8)依我们的自行判断，足以令人反感的内容，或者限制或妨碍他人使用或享受互动区域或平台的内容，或者可能使您遭致任何类型损害或责任的内容;
          <br />
           6.3如您的操作影响系统总体稳定性或完整性，我们将暂停或终止您的操作，直到相关问题得到解决。
          <br />
           6.4您已知悉并同意：
          <br />
           6.4.1您违反上述承诺时，我们有权依据本协议的约定，做出相应处理或终止向您提供服务，且无须征得您的同意或提前通知于您;
          <br />
           6.4.2根据相关法律法规的规定或者平台服务规则的判断，您的行为涉嫌违反法律法规的规定或违反本协议约定的，我们有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息或直接停止提供服务。如因此使我们遭受损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿我们因此造成的损失及发生的费用，包括不限于律师费用、诉讼费用、保全费用、鉴定费用等。
          <br />
           七、平台合作第三方
          <br />
           7.1 对于平台展示的所有第三方机构，平台不参与任何的资金融通行为，且并不实质性或变相介入您与第三方之间的交易，对此，您充分理解并认可。平台不对该类任何资金融通行为进行背书或担保。平台提供的服务中不带有对您、任何交易的任何保证或条件，无论是明示或默示。
          <br />
           7.2您理解并同意，在您通过第三方机构平台或系统访问或使用相关服务的过程中，您应自主、审慎进行判断，并自行承担您在第三方机构平台中的点击、访问、操作、交易等全部行为的法律责任。本平台不承担任何法律责任。
          <br />
           7.3针对以下情形，本平台不承担任何责任：
          <br />
           7.3.1因使用第三方服务、产品等内容，或据此进行交易等行为，而引致的人身伤害、财产损失(包括但不限于因浏览、注册、登录、下载等操作而感染病毒，导致硬件损坏或数据丢失，因使用相关产品服务而产生的经济损失等)、名誉或商誉损害、版权或知识产权等权利的侵害等事件;
          <br />
           7.3.2第三方平台或系统故障，导致您无法正常访问或相关服务无法持续、稳定提供的;
          <br />
           7.3.3第三方提供的相关服务内容违反现行法律法规的规定，被有权部门关停或责令整改，导致您无法正常使用其服务的;
          <br />
           7.3.4因第三方的原因导致您的个人信息保护措施不到位;
          <br />
           7.3.5其他不可归责于本平台的情形。
          <br />
           八、风险提示及免责声明
          <br />
           8.1就您在本平台注册后所能享受的服务内容，本平台已在显著位置进行了相关风险提示，因此本平台不以任何明示或默示的方式对您使用本平台服务而产生的在已提示风险内的任何形式的直接或间接损失承担法律责任。
          <br />
           8.2非平台原因可能产生的风险
          <br />
           对黑客行为、计算机或手机病毒、或因您保管疏忽致使帐号被他人非法使用、盗用、篡改或丢失，或由于与【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5站链接的其它平台或{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5所造成您个人资料的泄露，或您因其他非平台原因造成的损失，我们不承担责任。如您发现任何非法使用您帐号或安全漏洞的情况，请立即与我们联系。
          <br />
           8.3因本平台服务中断或终止可能产生的风险
          <br />
           8.3.1基于互联网环境的特殊性，本平台无法保证本平台的服务不会中断，对于包括但不限于本平台及相关第三方的系统、设备等存在缺陷、发生故障或定期维护所导致的服务中断，本平台不承担任何法律责任;
          <br />
           8.3.2因适用的任何法律、法规、政策等发生变化，导致本平台无法继续为您提供相关服务的，本平台不承担任何法律责任。我们不保证服务一定能满足您的要求;也不保证服务的及时性、安全性、准确性。
          <br />
           九、服务变更、中断或终止
          <br />
           9.1您同意，【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5基于平台服务的安全性，有权中止向您提供部分或全部服务，暂时冻结您的账户，待安全问题解决后及时恢复，并对中止、冻结及恢复的事实及时通知。
          <br />
           9.2发生下列任何一种情形，我们有权单方面中断或终止向您提供服务而无需通知您，且无需对您或第三方承担任何责任：
          <br />
           (1)认为您提供的个人资料不符合法律规定或非真实有效信息;
          <br />
           (2)发现异常交易或交易存在异议或有违法嫌疑时;
          <br />
           (3)用户个人账户被注销;
          <br />
           (4)有理由认为违反国家法律或侵害{signature}及其合作企业或他人利益的情形。
          <br />
           9.3您可随时通知我们终止向您提供服务或直接取消平台服务。自您终止或取消服务之日起，我们不再向您承担任何形式的责任。
          <br />
           9.4您同意，{signature}可能会在发生平台合作方终止合作、法律法规明确要求及监管政策变化等情形时中止或终止向您提供部分或全部服务，因此导致您无法使用本服务或服务受到限制的，不视为违约。
          <br />
           十、通知和送达
          <br />
           10.1您在注册成为【{signature}】{ adChannelCode === 'UHdvT0' ? '' : 'APP/'}H5的用户时，应当提供真实有效的联系方式(包括您的电子邮箱、联系电话、联系地址等)，对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
          <br />
           10.2本协议项下的通知如以公示方式作出，一经在平台公示即视为已经送达。
          <br />
           10.3除此之外，其他向您个人发布的具有专属性的通知将由平台向您在注册时提供的电子邮箱，或平台在您的个人账户中为您设置的站内消息系统栏，或您在注册后在平台绑定的手机发送，一经发送即视为已经送达。
          <br />
           10.4请您密切关注您的电子邮箱、站内消息系统栏中的邮件、信息及手机中的短信信息。您同意平台出于向您提供服务之目的，可以向您的电子邮箱、站内消息系统栏和手机发送有关通知或提醒;若您希望设置不接收或不愿意接收此类通知，您可通过客服电话联系我们，进行设置上的修改及保存。
          <br />
           我方邮箱信息：kefu@jukerong.com
          <br />
           客服电话：4008-116-568
          <br />
           十一、适用法律和管辖
          <br />
           11.1本服务协议的签订、效力、履行、终止、解释和争端解决受中华人民共和国法律法规的管辖。
          <br />
           11.2如就本协议内容或与本协议有关的任何争议，双方应友好协商解决，如协商未能达成一致，任一方均有权向提请深圳仲裁委员会按照其仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。
          <br />
           十二、其他
          <br />
           12.1协议效力具有可分性。本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
          <br />
           12.2如本服务协议与具体服务产品或服务协议有不一致之处，以具体产品或服务的协议为准，本协议与具体服务产品或服务协议构成完整协议，具体产品或服务协议约定不明的，适用本协议。
          <br />
           12.3本协议自用户勾选同意\网上签署或实际成功注册成为本平台用户之日起生效，除非{signature}终止本协议或用户丧失本服务用户资格，否则本协议长期有效。本协议终止时，双方权利义务未结清的，不免除{signature}及用户根据本协议或其他有关协议、规则所应享有或负担的权利义务。
          <br />
           12.4本平台对本服务协议保留最终解释的权利。
          <br />
           您的手机号码：【{phone ? phone : ''}】
          <br />
           签署时间：【{ formatTime(date, 'YYYY')}】年【{ formatTime(date, 'MM')}】月【{ formatTime(date, 'DD')}】日
          <br />
        </p>
      </>
    )
  }

  return (
    <div className={classNames(styles.privacyAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="注册协议" />
      }
      {
        jkrRegister()
      }
    </div>
  )
}

export default JukerongRegisterAgreement