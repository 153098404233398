/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef, useMemo } from 'react'
import styles from './rematch-waiting.module.scss'
import Header from '@/components/header/header'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import indexApi, { DirectMatchInfo } from '@/apis'
import { DirectMatchStatus } from '@/constants/user'

interface Props{}

const WaitingSuccess: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()
  const options:any = search.get('options')
  const resData = options && JSON.parse(decodeURIComponent(options))

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(3)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const renderMatch = useMemo(() => {
    switch (true) {
      case channelValue?.includes('huanbeitheme'):
        return <img className={styles.rematchGif} src={require('@imgs/mint-green/rematch.gif')} />
      case channelValue.includes('shallowBlue'):
        return <img className={styles.rematchGif} src={require('@imgs/shallow-blue/rematch.gif')} />
      // case channelValue.includes('halo'):
      //   return <img className={styles.rematchGif} src={require('@imgs/middle-blue/rematch.gif')} />
      case channelValue.includes('jufu'):
        return <img className={styles.rematchGif} src={require('@imgs/jufu/rematch.gif')} />
      default:
        return <img className={styles.rematchGif} src={require('@imgs/gif/rematch.gif')} />
    }
  }, [channelValue])

  // 倒计时
  useEffect(() => {
    if (countdownTime > 0) {
      timer.current = setTimeout(() => {
        setCountdownTime(countdownTime - 1)
      }, 1000)
    } else {
      if (channelValue?.includes('fenqile')) {
        navigate(`/new-fill-information?options=${encodeURIComponent(JSON.stringify(resData))}`)
        return
      }
      // 公用授权页
      navigate(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(resData))}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime, channelValue])

  return (
    <section className={styles.authorizePage}>
      <Header />
      {
        renderMatch
      }
    </section>
  )
}

export default WaitingSuccess